import React, { useState, useEffect } from "react";
import Userhead from "./userhead";
import Usernav from "./usernav";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {jwtDecode} from "jwt-decode";

export default function Companydoc() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedClient, setSelectedClient] = useState(
    localStorage.getItem("selectedClient") || null 
  );
  const [clientName, setClientName] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setClientName(decoded.clientName || []);
      } catch (error) {
        console.error("Error decoding token:", error);
        setClientName([]); // Fallback to an empty array
      }
    }
  }, []);

  // Fetch uploaded files
  const fetchUploadedFiles = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/docfiles`);
      const data = response.data;

      let filteredFiles = data;
      if (selectedClient) {
        filteredFiles = data.filter(
          (file) => file?.clientName?.clientName === selectedClient
        );
      } else if (clientName.length > 0) {
        filteredFiles = data.filter((file) =>
          clientName.includes(file?.clientName?.clientName)
        );
      }

      setUploadedFiles(filteredFiles);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  useEffect(() => {
    fetchUploadedFiles();
  }, [selectedClient]);

  // Handle download functionality
  const handleDownload = (fileId) => {
    const url = `${apiUrl}/api/docdownload/${fileId}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <Userhead onClientChange={setSelectedClient} />
      <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        <div
          className="sidebar-container"
          style={{
            width: "250px",
            flexShrink: 0,
          }}
        >
          <Usernav />
        </div>

        <div
          style={{
            flex: 1,
            marginLeft: "250px", // Matches the sidebar width
            marginTop: "74px", // Matches the header height
            overflow: "auto", // Scrollable content
            padding: "20px",
          }}
        >
          <h2>Company Documents</h2>
          <DataTable value={uploadedFiles} rows={10} style={{ width: "100%" }}>
            <Column
              field="fileName"
              header="File Name"
              body={(rowData) => {
                const fileName = rowData.fileName.split("_").slice(1).join("_");
                return <span>{fileName}</span>;
              }}
              style={{ width: "60%" }}
            />

            <Column
              body={(rowData) => (
                <div style={{ display: "flex", gap: "10px" }}>
                  <Button
                    icon="pi pi-download"
                    onClick={() => handleDownload(rowData._id)}
                  />
                </div>
              )}
              header="Downloads"
              style={{ width: "20%" }}
            />
          </DataTable>
        </div>
      </div>
    </>
  );
}
