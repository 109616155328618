import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast"; // Import Toast component
import CustomDialog from "../admin/component/pop-up";
import Navbar from "../admin/component/Navbar";
import axios from "axios";
import "./pagestyle.css";
import Header from "../admin/component/Header";

export default function UserType() {
  const [visible, setVisible] = useState(false);
  const [userTypeData, setUserTypeData] = useState({ userType: "" });
  const [userTypeList, setUserTypeList] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const toast = React.useRef(null); // Toast reference

  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch user types on component mount
  useEffect(() => {
    fetchUserTypes();
  }, []);

  const fetchUserTypes = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/usertypes`);
      const data = await response.json();
      setUserTypeList(data);
    } catch (error) {
      console.error("Error fetching user types:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch user types.",
        life: 3000,
      });
    }
  };

  const openDialog = () => {
    setVisible(true);
  };

  const closeDialog = () => {
    setVisible(false);
    setUserTypeData({ userType: "" });
    setEditingIndex(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userTypeData.userType) {
      try {
        if (editingIndex !== null) {
          const userId = userTypeList[editingIndex]._id;
          await fetch(`${apiUrl}/api/usertypes/${userId}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userType: userTypeData.userType }),
          });
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "User type updated successfully.",
            life: 3000,
          });
        } else {
          await fetch(`${apiUrl}/api/usertypes`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userType: userTypeData.userType,
            }),
          });
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "User type added successfully.",
            life: 3000,
          });
        }
        fetchUserTypes(); // Refresh the list
        closeDialog();
      } catch (error) {
        console.error("Error adding/editing user type:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to add/edit user type.",
          life: 3000,
        });
      }
    }
  };

  const handleEdit = (index) => {
    setUserTypeData({ userType: userTypeList[index].userType });
    setEditingIndex(index);
    openDialog();
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setConfirmVisible(true);
  };

  const confirmDelete = async () => {
    const userId = userTypeList[deleteIndex]?._id;
    const userType = userTypeList[deleteIndex]?.userType;

    const fileResponse = await axios.get(`${apiUrl}/api/files`);

    const filedata = fileResponse?.data.filter(
      (file) => file.userType?.userType === userType
    );

    const docResponse = await axios.get(`${apiUrl}/api/docfiles`);

    const docdata = docResponse?.data.filter(
      (file) => file.userType?.userType === userType
    );

    if (filedata.length > 0 || docdata.length > 0) {
      toast.current.show({
        severity: "warn",
        summary: "Cannot Delete",
        detail: "Data files for this UserType exist, cannot delete.",
        life: 3000,
      });
      setConfirmVisible(false);
      return;
    }

    try {
      await fetch(`${apiUrl}/api/usertypes/${userId}`, {
        method: "DELETE",
      });
      fetchUserTypes(); // Refresh the list
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "User type deleted successfully.",
        life: 3000,
      });
    } catch (error) {
      console.error("Error deleting user type:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to delete user type.",
        life: 3000,
      });
    } finally {
      setDeleteIndex(null);
      setConfirmVisible(false);
    }
  };

  const fields = [
    { name: "userType", label: "User Permissions", icon: "pi pi-id-card" },
  ];

  return (
    <>
      <Header />
      <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        {/* Sidebar */}
        <div
          className="sidebar-container"
          style={{
            width: "250px",
            flexShrink: 0,
          }}
        >
          <Navbar />
        </div>

        {/* Main Content */}
        <div
          style={{
            flex: 1,
            marginLeft: "250px",
            marginTop: "74px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh - 74px)",
          }}
        >
          <Toast ref={toast} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>
              {editingIndex !== null
                ? "Edit User Permissions"
                : "Add User Permissions"}
            </h2>
            <Button icon="pi pi-plus" onClick={openDialog} />
          </div>

          <CustomDialog
            visible={visible}
            onHide={closeDialog}
            fields={fields}
            handleSubmit={handleSubmit}
            data={userTypeData}
            setData={setUserTypeData}
          />

          <ConfirmDialog
            visible={confirmVisible}
            onHide={() => setConfirmVisible(false)}
            message="Are you sure you want to delete this user type?"
            header="Delete Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={confirmDelete}
            reject={() => setConfirmVisible(false)}
          />

          <div style={{ flex: 1, overflow: "auto" }}>
            <DataTable
              value={userTypeList}
              scrollable
              scrollHeight="650px"
              style={{ width: "100%" }}
              tableStyle={{ minWidth: "50rem" }}
              className="p-datatable-sm"
            >
              <Column field="userType" header="User Permissions" />
              <Column
                header="Actions"
                body={(rowData, { rowIndex }) => (
                  <div style={{ display: "flex", gap: "20px" }}>
                    <Button
                      icon="pi pi-pencil"
                      onClick={() => handleEdit(rowIndex)}
                    />
                    <Button
                      icon="pi pi-trash"
                      onClick={() => handleDelete(rowIndex)}
                    />
                  </div>
                )}
                style={{ width: "250px" }}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
}
