import React, { useState, useEffect } from "react";
import axios from "axios";
import Usernav from "./usernav";
import Userhead from "./userhead";
import { jwtDecode } from "jwt-decode";

export default function Companydetail() {
  const [userList, setUserList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(
    localStorage.getItem("selectedClient") || null
  );
  const [clientName, setClientName] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      return;
    }
    try {
      const decoded = jwtDecode(token);
      setClientName(decoded.clientName || []);
      if (!selectedClient && decoded.clientName?.length) {
        const defaultClient = decoded.clientName[0];
        setSelectedClient(defaultClient);
        localStorage.setItem("selectedClient", defaultClient);
      }
    } catch (e) {
      console.error("Invalid token", e);
    }
  }, [selectedClient]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/clients`);
        let filteredClients = response.data;

        if (selectedClient) {
          filteredClients = response.data.filter(
            (client) => client.clientName === selectedClient
          );
        } else if (clientName.length) {
          filteredClients = response.data.filter((client) =>
            clientName.includes(client.clientName)
          );
        }
        setUserList(filteredClients);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    fetchClients();
  }, [selectedClient, clientName]);

  return (
    <>
      <Userhead onClientChange={setSelectedClient} />
      <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        <div
          className="sidebar-container"
          style={{
            width: "250px",
            flexShrink: 0,
          }}
        >
          <Usernav />
        </div>
        <div
          style={{
            flex: 1,
            marginLeft: "250px", // Matches the sidebar width
            marginTop: "74px", // Matches the header height
            overflow: "auto", // Scrollable content
            padding: "20px",
          }}
        >
          <h2>Company Details</h2>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            {userList.map((company, index) => (
              <div
                key={index}
                style={{
                  boxShadow: "0 1px 2px 1px rgba(0, 0, 0, 0.24)",
                  padding: "15px",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "150px 1fr",
                    gap: "10px",
                  }}
                >
                  <div>
                    <strong>Company Name :</strong>
                  </div>
                  <div>{company.clientName}</div>
                  <div>
                    <strong>Address :</strong>
                  </div>
                  <div>{company.address}</div>
                  <div>
                    <strong>Phone Number :</strong>
                  </div>
                  <div>{company.phoneNumber}</div>
                  <div>
                    <strong>Federal ID :</strong>
                  </div>
                  <div>{company.FederalId}</div>

                  <div>
                    <strong>State ID :</strong>
                  </div>
                  <div>{company.StateId}</div>

                  <div>
                    <strong>No. of Employees :</strong>
                  </div>
                  <div>{company.NoOfEmployes}</div>

                  <div>
                    <strong>Start Date :</strong>
                  </div>
                  <div>
                    {new Date(company.StartDate).toLocaleDateString("en-GB")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
