import React from "react";
import Userhead from "./userhead";
import Usernav from "./usernav";

export default function ClientDashboard() {
  return (
    <>
    <div>
    <Userhead />
    <div style={{ display: "flex", overflow: "hidden" }}>
      {/* Sidebar */}
      <div
        className="sidebar-container"
        style={{
          width: "250px",
          flexShrink: 0,
          zIndex:"0"
        }}
      >
        <Usernav />
      </div>
      </div>
        
        {/* Content Area */}
        <div style={{
          flex: 1,
          marginLeft: "250px", // Matches the sidebar width
          marginTop: "74px",   // Matches the header height
          overflow: "auto",    // Scrollable content
          padding: "20px",
        }}>
          <h2>Welcome to the Client Dashboard</h2>
          <p>Select an option from the menu to get started.</p>
        </div>
      </div>
    </>
  );
}
