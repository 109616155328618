import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import Header from "../admin/component/Header";
import Navbar from "../admin/component/Navbar";

export default function Activity() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    username: null,
    clientName: null,
    fromDate: null,
    toDate: null,
  });
  const [usernames, setUsernames] = useState([]);
  const [clients, setClients] = useState([]);
  const toast = React.useRef(null);

  const apiUrl = process.env.REACT_APP_API_URL;

    const fetchActivity = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/all`);
        setData(response.data);
        setFilteredData(response.data);

        const usernameList = [...new Set(response.data.map(activity => activity.userName))];
        const clientList = [
          ...new Set(
            response.data.flatMap(activity => activity.downloadedFiles.map(file => file.clientName))
          ),
        ];
        setUsernames(usernameList);
        setClients(clientList);
      } catch (error) {
        console.error("Error fetching activity logs:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch activity logs.",
          life: 3000,
        });
      }
    };


  
  useEffect(() => {
    let filtered = data;

    if (filters.username) {
      filtered = filtered.filter((activity) =>
        activity.userName === filters.username
      );
    }

    // Apply filter for client name if it's selected
    if (filters.clientName) {
      filtered = filtered.filter((activity) =>
        activity.downloadedFiles.some((file) =>
          file.clientName === filters.clientName
        )
      );
    }

    // Apply filter for start date if it's selected
    if (filters.fromDate) {
      filtered = filtered.filter((activity) =>
        activity.downloadedFiles.some((file) =>
          new Date(file.downloadTime) >= new Date(filters.fromDate)
        )
      );
    }

    // Apply filter for end date if it's selected
    if (filters.toDate) {
      filtered = filtered.filter((activity) =>
        activity.downloadedFiles.some((file) =>
          new Date(file.downloadTime) <= new Date(filters.toDate)
        )
      );
    }
    
    fetchActivity();
    setFilteredData(filtered);
  }, [filters]);

  const handleFilterChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const clearFilters = () => {
    setFilters({
      username: null,
      clientName: null,
      fromDate: null,
      toDate: null,
    });
  };

  const refresh=()=>{
    fetchActivity();
  }

  return (
    <>
      <Header />
      <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        <div
          className="sidebar-container"
          style={{
            width: "250px",
            flexShrink: 0,
          }}
        >
          <Navbar />
        </div>
        <Toast ref={toast} />
        <div
          style={{
            flex: 1,
            marginLeft: "250px",
            marginTop: "74px",
            overflow: "auto",
            padding: "20px",
          }}
        >
          <h2>Activity Logs</h2>

          {/* Filter Section */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              gap: "10px",
            }}
          >
            <Calendar
              value={filters.fromDate}
              onChange={(e) => handleFilterChange("fromDate", e.value)}
              placeholder="Start Date"
              dateFormat="dd-mm-yy"
              showIcon
            />
            <Calendar
              value={filters.toDate}
              onChange={(e) => handleFilterChange("toDate", e.value)}
              placeholder="End Date"
              dateFormat="dd-mm-yy"
              showIcon
            />
            <Dropdown
              value={filters.username}
              options={usernames.map((username) => ({ label: username, value: username }))}
              onChange={(e) => handleFilterChange("username", e.value)}
              placeholder="Select Username"
            />
            <Dropdown
              value={filters.clientName}
              options={clients.map((client) => ({ label: client, value: client }))}
              onChange={(e) => handleFilterChange("clientName", e.value)}
              placeholder="Select Client Name"
            />
            
            <Button icon="pi pi-filter-slash" onClick={clearFilters} />
            <Button icon="pi pi-refresh" onClick={refresh}/>
          </div>

          {/* Activity Table */}
          <table border="1" style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th>User Name</th>
                <th>Client Name</th>
                <th>File Name</th>
                <th>Download Time</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((activity) =>
                  activity.downloadedFiles.map((file, index) => (
                    <tr key={`${activity._id}-${index}`}>
                      <td>{activity.userName}</td>
                      <td>{file.clientName}</td>
                      <td>{file.fileName}</td>
                      <td>{new Date(file.downloadTime).toLocaleString()}</td>
                    </tr>
                  ))
                )
              ) : (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    No activity logs found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
