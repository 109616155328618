import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import FileUploadPage from "./pages/file-upload.js";
import Clients from "./pages/clients.js";
import Users from "./pages/users.js";
import UserTypes from "./pages/user-types.js";
import FileTypes from "./pages/file-types.js";
import ClientDashboard from "./user/component/clientdashboard.js";
import Companydetail from "./user/component/companydetail.js";
import Reports from "./user/component/reports.js";
import Activity from "./pages/activity.js";
import Login from "./login.js";
import Dashboard from "./pages/dashboard.js";
import { jwtDecode } from "jwt-decode";
import Companydoc from "./user/component/companydoc.js";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      setIsAuthenticated(true);

      const decoded = jwtDecode(token);
      const userType = decoded.userType;

      
      navigate(userType === "Admin" ? "/dashboard" : "/clientdashboard");
    }
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  return (
    <>
      <Routes>
        // admin route
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route
          path="/dashboard"
          element={
            isAuthenticated ? <Dashboard /> : <Login onLogin={handleLogin} />
          }
        />
        <Route
          path="/"
          element={
            isAuthenticated ? <Dashboard /> : <Login onLogin={handleLogin} />
          }
        />
        <Route
          path="/file-upload"
          element={
            isAuthenticated ? (
              <FileUploadPage />
            ) : (
              <Login onLogin={handleLogin} />
            )
          }
        />
        <Route
          path="/clients"
          element={
            isAuthenticated ? <Clients /> : <Login onLogin={handleLogin} />
          }
        />
        <Route
          path="/users"
          element={
            isAuthenticated ? <Users /> : <Login onLogin={handleLogin} />
          }
        />
        <Route
          path="/user-permissions"
          element={
            isAuthenticated ? <UserTypes /> : <Login onLogin={handleLogin} />
          }
        />
        <Route
          path="/reports-types"
          element={
            isAuthenticated ? <FileTypes /> : <Login onLogin={handleLogin} />
          }
        />
        <Route
          path="/activity"
          element={
            isAuthenticated ? <Activity /> : <Login onLogin={handleLogin} />
          }
        />
        // user route
        <Route
          path="/clientdashboard"
          element={
            isAuthenticated ? (
              <ClientDashboard />
            ) : (
              <Login onLogin={handleLogin} />
            )
          }
        />
        <Route
          path="/reports/:fileType"
          element={
            isAuthenticated ? <Reports /> : <Login onLogin={handleLogin} />
          }
        />
        <Route
          path="/companydetail"
          element={
            isAuthenticated ? (
              <Companydetail />
            ) : (
              <Login onLogin={handleLogin} />
            )
          }
        />
        <Route
          path="/companydoc"
          element={
            isAuthenticated ? <Companydoc /> : <Login onLogin={handleLogin} />
          }
        />
      </Routes>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <App />
  </Router>
);

reportWebVitals();
