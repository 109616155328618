import React, { useState, useEffect } from "react";
import {jwtDecode} from "jwt-decode"; // Fix import
import { Button } from "primereact/button";
import { AutoComplete } from "primereact/autocomplete"; // Import AutoComplete
import { ProgressSpinner } from "primereact/progressspinner";
import "./userstyle.css";
import { useNavigate } from "react-router-dom";

export default function Userhead({ onClientChange }) {
  const [userData, setUserData] = useState({
    Name: null,
    clientName: null,
    userType: null,
  });
  const [selectedClient, setSelectedClient] = useState(
    localStorage.getItem("selectedClient") || null
  );
  const [clientSuggestions, setClientSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getUserDataFromToken = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      return { Name: null, clientName: null, userType: null };
    }

    try {
      const decoded = jwtDecode(token);
      return {
        Name: decoded.Name || null,
        clientName: decoded.clientName || null,
        userType: decoded.userType || null,
      };
    } catch (e) {
      console.error("Invalid token", e);
      return { Name: null, clientName: null, userType: null };
    }
  };

  useEffect(() => {
    const userDataFromToken = getUserDataFromToken();
    setUserData(userDataFromToken);

    // Set default client for CPA user if not already set
    if (
      userDataFromToken.userType === "CPA" &&
      userDataFromToken.clientName &&
      !selectedClient
    ) {
      const defaultClient = userDataFromToken.clientName[0];
      setSelectedClient(defaultClient);
      localStorage.setItem("selectedClient", defaultClient);
    }
  }, []);

  useEffect(() => {
    if (onClientChange) {
      onClientChange(selectedClient);
    }
  }, [selectedClient, onClientChange]);

  const handleLogout = () => {
    setLoading(true);
    localStorage.removeItem("authToken");
    localStorage.removeItem("selectedClient");
    sessionStorage.removeItem("authToken");
    setUserData({ Name: null, clientName: null, userType: null });

    setTimeout(() => {
      setLoading(false);
      navigate("/login");
      window.location.reload();
    }, 1000);
  };

  const handleClientSearch = (event) => {
    const query = event.query.toLowerCase();
    const suggestions = userData.clientName?.filter((client) =>
      client.toLowerCase().includes(query)
    );
    setClientSuggestions(suggestions || []);
  };

  const handleClientSelect = (client) => {
    setSelectedClient(client.value);
    localStorage.setItem("selectedClient", client.value);
  };

  return (
    <header className="header-container">
      <div className="header-logo">
        <img src="/logo.png" alt="Logo" className="logo-image" />
      </div>
      <h1>
        {userData.userType === "CPA" ? (
          <AutoComplete
            value={selectedClient}
            suggestions={clientSuggestions}
            completeMethod={handleClientSearch}
            onChange={(e) => handleClientSelect(e)}
            placeholder="Select Client"
            className="client-autocomplete"
            dropdown
          />
        ) : (
          userData.clientName || "Client"
        )}
      </h1>
      <div className="user-icon">
        <span>Welcome, {userData.Name || "Guest"}!</span>
        <img src="/images/user-icon.png" alt="User Icon" />
        <div className="card flex justify-content-center">
          <Button
            label="Logout"
            onClick={handleLogout}
            style={{ marginLeft: "20px" }}
          />
        </div>
      </div>

      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <div className="fullscreen-loader">
            <ProgressSpinner animationDuration="1.5s" />
            <p style={{ color: "white", marginTop: "15px" }}>Logging out...</p>
          </div>
        </div>
      )}
    </header>
  );
}
