import React from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { useNavigate,useLocation } from "react-router-dom";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation(); 


  return (
    <div className="usernav-container">
      <Sidebar className="sidebar-container">
        <Menu iconShape="circle">
          <MenuItem
            icon={<i className="pi pi-home" />}
            onClick={() => navigate("/dashboard")}
            className={`menu-item submenu ${
              location.pathname === "/dashboard"
                ? "selected-menu-item"
                : ""
            }`}
          >
            Dashboard
          </MenuItem>

          <MenuItem
            icon={<i className="pi pi-upload" />}
            onClick={() => navigate("/file-upload")}
            className={`menu-item submenu ${
              location.pathname === "/file-upload"
                ? "selected-menu-item"
                : ""
            }`}
          >
          File Upload
          </MenuItem>
          <MenuItem
            icon={<i className="pi pi-users" />}
            onClick={() => navigate("/clients")}
            className={`menu-item submenu ${
              location.pathname === "/clients"
                ? "selected-menu-item"
                : ""
            }`}
          >
          Clients
          </MenuItem>
          <MenuItem
            icon={<i className="pi pi-user" />}
            onClick={() => navigate("/users")}
            className={`menu-item submenu ${
              location.pathname === "/users"
                ? "selected-menu-item"
                : ""
            }`}
          >
          Users
          </MenuItem>
          <MenuItem
            icon={<i className="pi pi-id-card" />}
            onClick={() => navigate("/user-permissions")}
            className={`menu-item submenu ${
              location.pathname === "/user-permissions"
                ? "selected-menu-item"
                : ""
            }`}
          >
          User Permissions
          </MenuItem>
          <MenuItem
            icon={<i className="pi pi-file" />}
            onClick={() => navigate("/reports-types")}
            className={`menu-item submenu ${
              location.pathname === "/reports-types"
                ? "selected-menu-item"
                : ""
            }`}
          >
          Reports Types
          </MenuItem>
          <MenuItem
            icon={<i className="pi pi-chart-line" />}
            onClick={() => navigate("/activity")}
            className={`menu-item submenu ${
              location.pathname === "/activity"
                ? "selected-menu-item"
                : ""
            }`}
          >
            Activity
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default Navbar;
