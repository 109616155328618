// src/dashboard.js

import React from "react";
import Navbar from "../admin/component/Navbar";
import Header from "../admin/component/Header";


const Dashboard = () => {
  return (
    <>
    <div>
    <Header />
    <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      {/* Sidebar */}
      <div
        className="sidebar-container"
        style={{
          width: "250px",
          flexShrink: 0,
        }}
      >
        <Navbar />
      </div>

      <div style={{
        flex: 1,
        marginLeft: "250px", // Matches the sidebar width
        marginTop: "74px",   // Matches the header height
        overflow: "auto",    // Scrollable content
        padding: "20px",
      }}>
        <h2>Welcome to the Admin Dashboard</h2>
        <p>Select an option from the menu to get started.</p>
      </div>
    </div></div>
    </>
  );
};

export default Dashboard;
