import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../component/style.css";
import { jwtDecode } from "jwt-decode"; // Correct import
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Set initial loading state to false

  const getUsernameFromToken = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      return null;
    }

    try {
      const decoded = jwtDecode(token);
      return decoded.Name;
    } catch (e) {
      console.error("Invalid token", e);
      return null;
    }
  };

  const Name = getUsernameFromToken();

  const handlelogout = () => {
    setLoading(true); // Set loading state to true when logout is triggered
    localStorage.removeItem("authToken");
    sessionStorage.removeItem('authToken');
    setTimeout(() => {
      navigate("/login");
      window.location.reload();
    }, 1000); // Simulate a small delay before redirecting
  };

  const getPageTitle = () => {
    switch (location.pathname) {
      case "/dashboard":
        return "Admin Dashboard";
      case "/file-upload":
        return "File Upload";
      case "/clients":
        return "Clients";
      case "/users":
        return "Users";
      case "/user-permissions":
        return "User Permissions";
      case "/reports-types":
        return "Reports Types";
      case "/activity":
        return "Activity";
      default:
        return "Admin Dashboard";
    }
  };

  return (
    <header className="header-container">
      {/* Fullscreen loader */}
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
          }}
        >
          <div className="fullscreen-loader">
            <ProgressSpinner animationDuration="1.5s" />
            <p style={{ color: 'white', marginTop: '15px' }}>Loging out...</p>
          </div>
        </div>
      )}

      <div className="header-logo">
        <img src="/logo.png" alt="Logo" className="logo-image" />
      </div>
      <h1 className="page-title">{getPageTitle()}</h1>
      <div className="user-icon">
        <span>Welcome, {Name || "Guest"}!</span>
        <img src="/images/user-icon.png" alt="User Icon" />
        <div className="card flex justify-content-center">
          <Button
            label="Logout"
            onClick={handlelogout}
            style={{ marginLeft: "20px" }}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
