import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast"; 
import CustomDialog from "../admin/component/pop-up";
import Header from "../admin/component/Header";
import Navbar from "../admin/component/Navbar";
import axios from "axios";
import "./pagestyle.css";

export default function FileType() {
  const [visible, setVisible] = useState(false);
  const [fileTypeData, setFileTypeData] = useState({ fileType: "" });
  const [fileTypeList, setFileTypeList] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const toast = React.useRef(null); // Toast reference

  useEffect(() => {
    fetchFileTypes();
  }, []);

  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchFileTypes = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/filetypes`);
      const data = await response.json();
      setFileTypeList(data);
    } catch (error) {
      console.error("Error fetching file types:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch file types.",
        life: 3000,
      });
    }
  };

  const openDialog = () => {
    setVisible(true);
  };

  const closeDialog = () => {
    setVisible(false);
    setFileTypeData({ fileType: "" });
    setEditingIndex(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("authToken");

    if (fileTypeData.fileType) {
      try {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };

        if (editingIndex !== null) {
          const fileTypeId = fileTypeList[editingIndex]._id;
          await fetch(`${apiUrl}/api/filetypes/${fileTypeId}`, {
            method: "PUT",
            headers,
            body: JSON.stringify({ fileType: fileTypeData.fileType }),
          });
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "File type updated successfully.",
            life: 3000,
          });
        } else {
          await fetch(`${apiUrl}/api/filetypes`, {
            method: "POST",
            headers,
            body: JSON.stringify({
              fileType: fileTypeData.fileType,
            }),
          });
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "File type added successfully.",
            life: 3000,
          });
        }
        fetchFileTypes();
        closeDialog();
      } catch (error) {
        console.error("Error adding/editing file type:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to add/edit file type.",
          life: 3000,
        });
      }
    }
  };

  const confirmDelete = async () => {
    const fileTypeId = fileTypeList[deleteIndex]._id;
    const fileType = fileTypeList[deleteIndex].fileType;

    const fileResponse = await axios.get(`${apiUrl}/api/files`);

    const filedata = fileResponse.data.filter((file) =>
      file.fileType?.some((ft) => ft.fileType === fileType)
    );

    const docResponse = await axios.get(`${apiUrl}/api/docfiles`);

    const docdata = docResponse?.data.filter((file) =>
      file.fileType?.some((ft) => ft.fileType === fileType)
    );

    if (filedata.length > 0 || docdata.length > 0) {
      toast.current.show({
        severity: "warn",
        summary: "Cannot Delete",
        detail: "Data files for this fileType exist, cannot delete.",
        life: 3000,
      });
      setConfirmVisible(false);
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/api/filetypes/${fileTypeId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        setFileTypeList(fileTypeList.filter((_, i) => i !== deleteIndex));
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "File type deleted successfully.",
          life: 3000,
        });
        setDeleteIndex(null);
        setConfirmVisible(false);
      }
    } catch (error) {
      console.error("Error deleting file type:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to delete file type.",
        life: 3000,
      });
    }
  };

  const handleEdit = (index) => {
    setFileTypeData({ fileType: fileTypeList[index].fileType });
    setEditingIndex(index);
    openDialog();
  };

  const handleDelete = (index) => {
    setDeleteIndex(index);
    setConfirmVisible(true);
  };

  const fields = [{ name: "fileType", label: "Reports", icon: "pi pi-file" }];

  return (
    <>
      <Header />
      <div style={{ display: "flex", height: "100vh", overflow: "hidden" }}>
        {/* Sidebar */}
        <div
          className="sidebar-container"
          style={{
            width: "250px",
            flexShrink: 0,
          }}
        >
          <Navbar />
        </div>
        <div
          style={{
            flex: 1,
            marginLeft: "250px", // Matches the sidebar width
            marginTop: "74px", // Matches the header height
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh - 74px)",
          }}
        >
          <Toast ref={toast} /> {/* Add Toast component here */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>{editingIndex !== null ? "Edit Reports" : "Add Reports"}</h2>
            <Button icon="pi pi-plus" onClick={openDialog} />
          </div>
          <CustomDialog
            visible={visible}
            onHide={closeDialog}
            fields={fields}
            handleSubmit={handleSubmit}
            data={fileTypeData}
            setData={setFileTypeData}
          />
          <ConfirmDialog
            visible={confirmVisible}
            onHide={() => setConfirmVisible(false)}
            message="Are you sure you want to delete this file type?"
            header="Delete Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={confirmDelete}
            reject={() => setConfirmVisible(false)}
          />
          {fileTypeList.length > 0 && (
            <DataTable
              value={fileTypeList}
              scrollable
              scrollHeight="650px"
              style={{ width: "100%" }}
              tableStyle={{ minWidth: "50rem" }}
              className="p-datatable-sm"
            >
              <Column field="fileType" header="Reports" />
              <Column
                header="Actions"
                body={(rowData, { rowIndex }) => (
                  <div style={{ display: "flex", gap: "20px" }}>
                    <Button
                      icon="pi pi-pencil"
                      onClick={() => handleEdit(rowIndex)}
                    />
                    <Button
                      icon="pi pi-trash"
                      onClick={() => handleDelete(rowIndex)}
                    />
                  </div>
                )}
                style={{ width: "250px" }}
              />
            </DataTable>
          )}
        </div>
      </div>
    </>
  );
}
