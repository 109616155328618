import React, { useState } from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { jwtDecode } from "jwt-decode";
import { ProgressSpinner } from "primereact/progressspinner";
import "./login.css";

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for the spinner
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${apiUrl}/api/login`, {
        username,
        password,
      });

      const { token } = response.data;
      localStorage.setItem("authToken", token);
      sessionStorage.setItem("authToken", token);
      const decoded = jwtDecode(token);
      const userType = decoded.userType;
      const userName = decoded.username; 


      if (userType !== "Admin") {
        await axios.post(`${apiUrl}/api/log/login`, {
          userName,
        });
      }

      onLogin();

      // Add a delay before navigating to allow spinner to show
      setTimeout(() => {
        if (userType === "Admin") {
          navigate("/dashboard");
        } else {
          navigate("/clientdashboard");
        }
        window.location.reload(); // Ensure the page reloads after navigation
      }, 1000); // Added delay of 1000ms (1 second) for spinner to be visible
    } catch (error) {
      setLoading(false); // Hide loader immediately if there's an error
      console.error(error);  // Log the error for debugging
    
      if (error.response && error.response.status === 400) {
        setErrorMessage("Invalid credentials");
      } else {
        setErrorMessage("Server error. Please try again later.");
      }
    }
    
  };

  return (
    <div className="flex justify-content-center align-items-center min-h-screen">
      <Card
        title="Login"
        className="p-4 shadow-3 login-card"
        style={{
          width: "400px",
          borderRadius: "15px",
          background: "linear-gradient(145deg, #ffffff, #e6e6e6)",
          boxShadow: "8px 8px 15px #bebebe, -8px -8px 15px #ffffff",
        }}
      >
        <form onSubmit={handleLogin}>
          <div className="p-field p-inputgroup mb-3">
            <span className="p-inputgroup-addon">
              <i className="pi pi-user"></i>
            </span>
            <InputText
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
            />
          </div>
          <div className="p-field p-inputgroup mb-3">
            <span className="p-inputgroup-addon">
              <i className="pi pi-lock"></i>
            </span>
            <InputText
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
          </div>
          {errorMessage && <div className="p-error mb-3">{errorMessage}</div>}

          <Button
            label="Login"
            type="submit"
            className="p-button-primary w-full"
            disabled={loading}
          />
        </form>
      </Card>

      {/* Fullscreen loader */}
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.56)",
            zIndex: 9999,
          }}
        >
          <div className="fullscreen-loader">
            <ProgressSpinner animationDuration="1.5s" />
            <p style={{ color: "white", marginTop: "15px" }}>Please wait...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
